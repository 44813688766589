import _createPlugin from "./lib/createPlugin";
import * as _postcssJs2 from "postcss-js";
var _postcssJs = _postcssJs2;
try {
  if ("default" in _postcssJs2) _postcssJs = _postcssJs2.default;
} catch (e) {}
import * as _picocolors2 from "picocolors";
var _picocolors = _picocolors2;
try {
  if ("default" in _picocolors2) _picocolors = _picocolors2.default;
} catch (e) {}
import _addPrefix from "./lib/addPrefix";
import _package from "../package.json";
import _utilities from "../dist/utilities";
import _base from "../dist/base";
import _unstyled from "../dist/unstyled";
import _styled from "../dist/styled";
import _utilitiesUnstyled from "../dist/utilities-unstyled";
import _utilitiesStyled from "../dist/utilities-styled";
import _themes from "./theming/themes";
import _functions from "./theming/functions";
import _utilityClasses from "./lib/utility-classes";
import _index from "./theming/index";
var exports = {};
// const tailwindColors = require("tailwindcss/colors")
// const tailwindPlugin = require("tailwindcss/plugin")
const tailwindPlugin = _createPlugin;
const postcssJs = _postcssJs;
const pc = _picocolors;
const postcssPrefix = _addPrefix;
const daisyuiInfo = _package;
const utilities = _utilities;
const base = _base;
const unstyled = _unstyled;
const styled = _styled;
const utilitiesUnstyled = _utilitiesUnstyled;
const utilitiesStyled = _utilitiesStyled;
const themes = _themes;
const colorFunctions = _functions;
const utilityClasses = _utilityClasses;
let colorObject = _index;
const mainFunction = ({
  addBase,
  addComponents,
  config
}) => {
  let logs = false;
  if (config("daisyui.logs") != false) {
    logs = true;
  }
  if (logs) {
    console.log();
    console.log(`🌼   ${pc.magenta("daisyUI")} ${pc.dim(daisyuiInfo.version)}`);
  }

  // inject @base style
  if (config("daisyui.base") != false) {
    addBase(base);
  }

  // inject components
  let file = styled;
  if (config("daisyui.styled") == false) {
    file = unstyled;
  }

  // add prefix to class names if specified
  const prefix = config("daisyui.prefix");
  let postcssJsProcess;
  if (prefix) {
    try {
      postcssJsProcess = postcssJs.sync(postcssPrefix({
        prefix,
        ignore: []
      }));
    } catch (error) {
      logs && console.error(`Error occurred and prevent applying the "prefix" option:`, error);
    }
  }
  const shouldApplyPrefix = prefix && postcssJsProcess;
  if (shouldApplyPrefix) {
    file = postcssJsProcess(file);
  }
  addComponents(file);
  const themeInjector = colorFunctions.injectThemes(addBase, config, themes);
  themeInjector;

  // inject @utilities style needed by components
  if (config("daisyui.utils") != false) {
    addComponents(utilities, {
      variants: ["responsive"]
    });
    let toAdd = utilitiesUnstyled; // shadow clone here to avoid mutate the original
    if (shouldApplyPrefix) {
      toAdd = postcssJsProcess(toAdd);
    }
    addComponents(toAdd, {
      variants: ["responsive"]
    });
    toAdd = utilitiesStyled;
    if (shouldApplyPrefix) {
      toAdd = postcssJsProcess(toAdd);
    }
    addComponents(toAdd, {
      variants: ["responsive"]
    });
  }
  if (logs) {
    if (config("daisyui.styled") == false) {
      console.log(`├─ ${pc.yellow("\u2139\uFE0E")} ${pc.blue("styled")} ${pc.reset("config is")} ${pc.blue("false")} ${pc.dim("\tcomponents won't have design decisions")}`);
    }
    if (config("daisyui.utils") == false) {
      console.log(`├─ ${pc.yellow("\u2139\uFE0E")} ${pc.blue("utils")} ${pc.reset("config is")} ${pc.blue("false")} ${pc.dim("\tdaisyUI utility classes are disabled")}`);
    }
    if (config("daisyui.prefix") && config("daisyui.prefix") !== "") {
      console.log(`├─ ${pc.green("\u2714\uFE0E")} ${pc.blue("prefix")} is enabled${pc.dim("\t\tdaisyUI classnames must use")} ${pc.blue(config("daisyui.prefix"))} ${pc.dim("prefix")}`);
    }
    if (themeInjector.themeOrder.length > 0) {
      console.log(`├─ ${pc.green("\u2714\uFE0E")} ${themeInjector.themeOrder.length} ${themeInjector.themeOrder.length > 1 ? "themes" : "theme"} added${pc.dim("\t\thttps://daisyui.com/docs/themes")}`);
    }
    if (themeInjector.themeOrder.length === 0) {
      console.log(`├─ ${pc.yellow("\u2139\uFE0E")} All themes are disabled in config${pc.dim("\t\thttps://daisyui.com/docs/themes")}`);
    }
    let messages = [`${pc.green("\u2764\uFE0E")} ${pc.reset("Support daisyUI project:")}\t${pc.dim(daisyuiInfo.funding.url)}`, `${pc.green("\u2605")} ${pc.reset("Star daisyUI on GitHub")}\t${pc.dim("https://github.com/saadeghi/daisyui")}`];
    console.log(`╰─ ${messages[Math.floor(Math.random() * messages.length)]}`);
    console.log();
  }
};
exports = tailwindPlugin(mainFunction, {
  theme: {
    extend: {
      colors: {
        ...colorObject,
        // adding all Tailwind `neutral` shades here so they don't get overridden by daisyUI `neutral` color
        "neutral-50": "#fafafa",
        "neutral-100": "#f5f5f5",
        "neutral-200": "#e5e5e5",
        "neutral-300": "#d4d4d4",
        "neutral-400": "#a3a3a3",
        "neutral-500": "#737373",
        "neutral-600": "#525252",
        "neutral-700": "#404040",
        "neutral-800": "#262626",
        "neutral-900": "#171717",
        "neutral-950": "#0a0a0a"
      },
      ...utilityClasses
    }
  }
});
export default exports;